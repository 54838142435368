<!-- StaffDetail.vue -->
<template>
  <div>
    <div class="part1">
      <h3>员工详情</h3>
      <Form :model="staff" label-width="100">
        <FormItem label="姓名"><Input v-model="staff.name" /></FormItem>
        <FormItem label="企业"><Input v-model="entityName" disabled /></FormItem>
        <FormItem label="部门"><Input v-model="staff.department" /></FormItem>
        <FormItem label="电话"><Input v-model="staff.phone" /></FormItem>
      </Form>
      <Button type="primary" @click="saveStaff">保存</Button>
    </div>
    <div class="part2">
      <h3>员工属性</h3>
      <Table :data="staffAttrs" :columns="attrColumns">
        <template slot-scope="{ row }" slot="action">
          <Button type="error" size="small" @click="deleteAttr(row)">删除</Button>
        </template>
      </Table>
      <Button type="primary" @click="addAttr">新增属性</Button>
      <Button type="primary" @click="saveAttrs">保存</Button>
    </div>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  props: {
    staffId: String, // 通过 props 接收 staffId
    entityName: String,
  },
  data() {
    return {
      staff: {},
      staffAttrs: [],
      attrOptions: [], // 新增：存储属性下拉列表选项
      attrColumns: [
        {
          title: '属性',
          key: 'attrId',
          render: (h, params) => {
            if (params.row.isNew) { // 新增行显示下拉列表
              return h('Select', {
                props: {
                  value: this.staffAttrs[params.index].attrId, // 直接绑定 entityAttrs 中的值
                },
                on: {
                  'on-change': (value) => {
                    this.$set(this.staffAttrs[params.index], 'attrId', value); // 更新 entityAttrs
                  }
                }
              }, this.attrOptions.map(option => h('Option', {
                props: {
                  value: option.id,
                  label: option.name
                }
              })));
            } else {
              return h('span', params.row.attr_name); // 已存在行显示属性名称
            }
          }
        },
        {
          title: '内容',
          key: 'content',
          render: (h, params) => h('Input', {
            props: {
              value: this.staffAttrs[params.index].content // 直接绑定 entityAttrs 中的值
            },
            on: {
              input: (val) => {
                this.$set(this.staffAttrs[params.index], 'content', val); // 更新 entityAttrs
              }
            }
          })
        },
        {title: '操作', slot: 'action'}
      ],
    };
  },
  watch: {
    staffId: {
      immediate: true, // 组件创建时立即执行
      handler(newVal) {
        if (newVal) {
          this.fetchStaffAttrs(); // 当 staffId 变化时获取数据
        }
      }
    }
  },
  methods: {
    fetchStaffAttrs() {
      post(`/wx/enterpriseStaff/info`, {'staffId': this.staffId}, (response) => {
        this.staff = {...response.data.entity.staff, entity_name: response.data.entity.staff.name};
        this.staffAttrs = response.data.entity.staffAttrs.map(item => ({
          ...item,
          attr_name: item.attrName,
          content: item.attrValue
        }));
        this.attrOptions = response.data.entity.attrs;
      });
    },
    addAttr() {
      const newAttr = {
        attrId: '',
        content: '',
        isNew: true
      };
      this.staffAttrs.push(newAttr);
      this.$forceUpdate(); // 强制更新视图（可选）
    },
    saveAttrs() {
      const attrsToSave = this.staffAttrs.map(attr => ({
        attrId: attr.attrId,
        attrValue: attr.content,
        entityAttrId: attr.entityAttrId,
        staffId: this.staffId,
      }));
      post('/wx/enterpriseStaff/saveAttrs', {'attrs': attrsToSave, 'staffId': this.staffId}, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('属性保存成功');
          this.fetchStaffAttrs(); // 刷新属性列表
        } else {
          this.$Message.error('属性保存失败');
        }
      });
    },
    saveStaff() {
      const data = {
        id: this.staff.id,
        name: this.staff.name,
        phone: this.staff.phone,
        department: this.staff.department,
      };
      post('/wx/enterprise/saveStaff', data , (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('保存成功');
          this.fetchStaffAttrs();
        } else {
          this.$Message.error('保存失败');
        }
      });
    },
    deleteAttr(row){
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除该企业属性？',
        onOk: () => {
          if (!row.entityAttrId) {
            this.staffAttrs = this.staffAttrs.filter(attr => attr.entityAttrId !== row.entityAttrId);
          } else {
            post('/wx/enterpriseStaff/deleteAttr', {id: row.entityAttrId}, (response) => {
              if (response && response.data && response.data.status === 'success') {
                this.$Message.success('属性删除成功');
                this.fetchStaffAttrs(); // 刷新属性列表
              } else {
                this.$Message.error('属性删除失败');
              }
            });
          }
        }
      });
    }
  }
};
</script>